<script setup>
import { useNavStore } from '@/stores/nav';

const navStore = useNavStore();

const props = defineProps({
    pageName: String,
});

navStore.setPage(props.pageName);
navStore.changeBg();
</script>

<template>
    <div></div>
</template>