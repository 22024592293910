<script setup>
import { computed, onMounted, onUnmounted, watch } from 'vue';
import { usePage, router } from '@inertiajs/vue3'
import { useNavStore } from '@/stores/nav';
import StepsWrapper from '@/Components/Mine/StepsWrapper.vue';
import StoreInfo from '@/Components/Mine/StoreInfo.vue';
import MudaCores from '@/Components/Mine/MudaCores.vue';
import FloatSquares from '@/Components/Mine/FloatSquares.vue';

const navStore = useNavStore();

defineProps({
    title: String,
    pageName: String,
});

router.on('start', (event) => {
  console.log(`Starting a visit to ${event.detail.visit.name}`)
})

const logoPequeno = computed(() => {
  return navStore.currentPage != 'index' && navStore.currentPage != 'results' && navStore.currentPage != 'assistencia'
})

const logoAssistencia = computed(() => {
  return navStore.currentPage == 'assistencia'
})
</script>

<template>
  <div class="w-screen h-screen overflow-hidden">
    <MudaCores :key="pageName" :page-name="pageName"></MudaCores>
    <FloatSquares :bg="navStore.bg" :logo-pequeno="logoPequeno" :logo-assistencia="logoAssistencia"></FloatSquares>
    <div class="fixed inset-20">
      <slot />
    </div>
    <div v-if="1>2">
      <StepsWrapper></StepsWrapper>
    </div>
  </div>
</template>

<style scoped>

.area{
  width: 100%;
  height:100vh;
}

.circles{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li{
  position: absolute;
  display: block;
  list-style: none;
  width: 40px;
  height: 40px;
  /* background: rgba(255, 255, 255); */
  animation: animate 25s linear infinite;
  bottom: -150px;

  .interior {
      @apply absolute w-[55%] h-[55%] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
  }
  
}

.circles li:nth-child(1){
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}


.circles li:nth-child(2){
  left: 10%;
  width: 70px;
  height: 70px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3){
  left: 70%;
  width: 80px;
  height: 80px;
  animation-delay: 4s;
}

.circles li:nth-child(4){
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5){
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6){
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7){
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8){
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9){
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10){
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}



@keyframes animate {

  0%{
      transform: translateX(-300px) translateY(0) rotate(9.73deg);
      opacity: 1;
      /* border-radius: 0; */
  }

  100%{
      transform: translateX(300px) translateY(-120dvh) rotate(9.73deg);
      opacity: 1;
      /* border-radius: 50%; */
  }

}
</style>